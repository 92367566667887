@use '../mixins/media-queries' as media;

.SideCart {
  position: fixed;
  top: 0;
  right: 0; 
  height: 100%;
  width: 100%;
  background-color: #fff;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%); /* Oculto fuera de la pantalla inicialmente */
  transition: transform 0.3s ease;
  z-index: 1001; /* Más alto que el overlay */
  overflow-y: auto;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;

  @include media.respond-to(desktop) {
    width: 520px;
  } 

  &.open {
    transform: translateX(0); /* Mueve el carrito hacia dentro de la pantalla */
  }
}

.SideCart__overlay {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 1000; 
  display: none;
}

.SideCart__overlay.active {
  display: block; /* Mostrar cuando el overlay está activo */
}

.SideCart__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

.SideCart__close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  .SideCart__close-icon {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }

  &:hover .SideCart__close-icon {
    transform: rotate(360deg);
  }
}

.SideCart__content {
  flex-grow: 1;
  margin-top: 15px;
  overflow-y: auto;
  overflow-x:hidden;
}

.SideCart__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;

  &-details {
    h4 {
      font-size:1rem;
      margin-bottom:0;
    }
  }

  img {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    object-fit: cover;
  }
}

.SideCart__quantity {
  display: flex;
  align-items: center;

  button {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  span {
    min-width: 30px;
    text-align: center;
    font-size: 1rem;
  }
}

.SideCart__remove {
  background: none;
  border: none;
  cursor: pointer;

  .SideCart__remove-icon {
    width: 30px;
    height: 30px;
  }
}

.SideCart__empty {
  padding: 65% 2%;
  text-align: center;
}

.SideCart__footer {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  button {
    width: 100%;
  }
}
