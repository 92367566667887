@use '../mixins/media-queries' as media;

$font-size-base: 1.2rem; 
$font-scale: 1.25; 


h1, h2, h3, h4, h5, h6 {
  margin: 0 0 1rem 0; 
  line-height: 1.2; 
}

h1, .h1 {
  font-size: calc(1.5 * $font-size-base);
  font-family: 'UniversPro65Bold';
  text-transform: uppercase;
}
h2, .h2 {
  font-size: calc(1.375 * $font-size-base);
  font-family: 'UniversPro65Bold';
  text-transform: uppercase;
}
h3, .h3 {
  font-size: calc(1.25 * $font-size-base);
}
h4, .h4 {
  font-size: calc(1.125 * $font-size-base);
}
h5, .h5 {
  font-size: calc(1.025 * $font-size-base);
}
h6, .h6 {
  font-size: calc(1 * $font-size-base);
}

@include media.respond-to(tablet) {
  h1, .h1 {
    font-size: calc(2.25 * $font-size-base);
  }
  h2, .h2 {
    font-size: calc(1.875 * $font-size-base);
  }
  h3, .h3 {
    font-size: calc(1.625 * $font-size-base);
  }
  h4, .h4 {
    font-size: calc(1.375 * $font-size-base);
  }
  h5, .h5 {
    font-size: calc(1.125 * $font-size-base);
  }
  h6, .h6 {
    font-size: calc(1 * $font-size-base);
  }
}

// Size for largest devices
@include media.respond-to(desktop) {
  h1, .h1 {
    font-size: calc(2.5 * $font-size-base); 
  }
  h2, .h2 {
    font-size: calc(2 * $font-size-base);
  }
  h3, .h3 {
    font-size: calc(1.75 * $font-size-base);
  }
  h4, .h4 {
    font-size: calc(1.5 * $font-size-base);
  }
  h5, .h5 {
    font-size: calc(1.25 * $font-size-base);
  }
  h6, .h6 {
    font-size: calc(1 * $font-size-base);
  }
}

