@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.RowIcon {
  position: relative;
  background-color:colors.$background-color;
  text-align: center;
  padding-bottom: 4rem;

  &__title {
    @include media.respond-to(desktop) {
      padding:0 25rem;
    } 
    @include media.respond-to(maxmobile) {
      padding:0 5rem!important;
    }
  }
  
  &__wave {
    position: absolute;
    top: -40%;
    z-index: -1;
    left: 0;
    @include media.respond-to(maxmobile) {
      top:-20%;
    }
  }

  &__content {
    padding:3rem;
    display: flex;
    justify-content: space-around;
    @include media.respond-to(maxmobile) {
      flex-direction: column;
    } 
  }

  &__card {
    background: white;
    margin-bottom: 8%;
    border-radius: 5px;
    padding: 1.5rem;
    text-align: center;
    -webkit-box-shadow: -3px -1px 13px 0px rgba(224,224,224,1);
    -moz-box-shadow: -3px -1px 13px 0px rgba(224,224,224,1);
    box-shadow: -3px -1px 13px 0px rgba(224,224,224,1);
    transition: 0.5s ease-in;
    @include media.respond-to(maxmobile) {
      width: 100%;
      margin-bottom:2rem!important;
    } 
    @include media.respond-to(desktop) {
      margin-bottom: 0;
    } 
    &:hover {
      transform:translateY(-20px);
      transition: 0.5s ease-in;
    }
    &--title {
      font-size: 1.5rem;
    }
    &--description {
      line-height: 1.3rem;
    }
  }

}