.CategoriesHeader {

  position: relative;
  background-position: center;
  background-size: 100%;
  padding: 7%;
  border-radius: 10px;
  margin: 2rem 0 4rem 0;
  text-align: center;
  color: white;
  overflow: hidden;
  -webkit-box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);
  -moz-box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);
  box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 61%); 
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 2;

    &--title {
      text-transform: uppercase;
      letter-spacing: 0.1rem;
    }

    &-subtitle {
      line-height: 1.5rem;
      font-size: 1.1rem;
    }

  }
    

}