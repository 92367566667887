.CategoriesPage {

  .categories-row {
    border-bottom: 1px solid rgb(0 0 0 / 7%);
    margin-bottom: 4rem;
    padding-bottom: 2rem;
    &:last-child {
      border-bottom: 0;
    }
    .category-item {
      display: flex;
      img {
        width: 92px;
        height: 92px;
        -webkit-box-shadow: -1px 2px 12px 3px rgb(181 181 181 / 23%);
        -moz-box-shadow: -1px 2px 12px 3px rgba(181, 181, 181, 0.53);
        box-shadow: -1px 2px 12px 3px rgb(181 181 181 / 16%);
        border-radius: 50px;
        transition: 0.8s ease-in;
        &:hover {
          transform:translateY(-20px);
          transition: 0.8s ease-in;
        }
      }
      h2 {
        font-size:1.3rem
      }
      .categories-list-links {
        margin-left:15px;
        ul li {
          margin-bottom: 10px;
        }
      }
    }

  }

}


.subcategory-todos {
  display: none;
}