@use '../variables/colors' as colors;

.search-bar {
  position: relative;
  width: 100%;

  .search-bar__icon {
    background-color:colors.$primary-color ;
    position: absolute;
    padding: 3px;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 4px;
    pointer-events: none;
  }

  input {
    width: 100%;
    padding: 10px 10px 10px 35px; // Agrega espacio para el icono
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 50px;
    &:focus {
      border: 1px solid colors.$primary-color;
      background: colors.$background-color;
      outline: none;
    }
  }

  .search-results {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;

    .search-result-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #f1f1f1;

      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 10px;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
      }
    }
  }
}
