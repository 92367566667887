@font-face {
  font-family: 'UniversPro55Roman';
  src: url('./fonts/UniversPro55Roman.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UniversPro65Bold';
  src: url('./fonts/UniversPro65Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

$body-regular:'UniversPro55Roman';
$body-medium:'UniversPro55Roman';
$body-ligth:'UniversPro55Roman';
$body-thin:'UniversPro55Roman';
$page-title:'UniversPro65Bold';
$heading-section:'UniversPro65Bold';
