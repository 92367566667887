@use '../variables/colors' as colors;

.DualSlider {

  overflow: hidden; 

  .slick-slider {
    overflow: hidden; 
  }
  
  .slick-slide {
   opacity: 0; 
  }

  .slick-slide.slick-active {
    opacity: 1;
  }

  &__title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2rem;
  }

  &__slide.DualSlider__slide--even {
    .DualSlider__slide--text {
      background: colors.$secondary-color;
      color:white;
    }
  }

  &__slide.DualSlider__slide--odd {
    .DualSlider__slide--text {
      background: #c612183b;
      color:colors.$secondary-color;
    }
  }

  &__slide {
    display: flex;
    align-items: center;
    padding: 20px 0;

    @media (min-width: 768px) {
      padding: 50px 0;
    }

    &--text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10%;
      text-align: center;

      .short--text {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 1.75rem;
        margin-bottom: 15px;
      }

      p {
        font-size: 1rem;
        line-height: 1.6;
      }
    }

    &--img {
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  .slick-dots {
    bottom: -30px;
    li button:before {
      color: #000;
    }
    li.slick-active button:before {
      color: #ff0000;
    }
  }

  // Estilos para las flechas
  .slick-prev,
  .slick-next {
    z-index: 1;
    font-size: 2rem;
    color: black;
    &:before {
      color: black;
    }
  }
}


.General__title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 50px;
  color: #333;
}