@use '../variables/colors' as colors;

button {
  padding: 12px 25px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn {
  padding: 12px 25px;
  border: none;
  text-transform: uppercase;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: colors.$primary-color;
  color: #ffffff;
  &:hover {
    color:#ffffff;
  }
}

.btn-secondary {
  background-color: colors.$secondary-color;
  color: #ffffff;
  &:hover {
    color:#ffffff;
  }
}

.btn-tertiary {
  background-color: colors.$eco-color;
  color: #ffffff;
  &:hover {
    color:#ffffff;
  }
}


/* Ripple Out */
@-webkit-keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}
.btn-primary {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.btn-primary:before {
  content: '';
  position: absolute;
  border: colors.$primary-color solid 3px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.btn-primary:hover:before, .btn-primary:focus:before, .btn-primary:active:before {
  -webkit-animation-name: hvr-ripple-out;
  animation-name: hvr-ripple-out;
}

/* Ripple In */
@-webkit-keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
@keyframes hvr-ripple-in {
  100% {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 1;
  }
}
.btn-secondary {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}
.btn-secondary:before {
  content: '';
  position: absolute;
  border: colors.$secondary-color solid 3px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.btn-secondary:hover:before, .btn-secondary:focus:before, .btn-secondary:active:before {
  -webkit-animation-name: hvr-ripple-in;
  animation-name: hvr-ripple-in;
}


.btn-tertiary {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
}

.btn-tertiary:before {
  content: '';
  position: absolute;
  border: colors.$eco-color solid 3px;
  top: -12px;
  right: -12px;
  bottom: -12px;
  left: -12px;
  opacity: 0;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.btn-tertiary:hover:before, .btn-tertiary:focus:before, .btn-tertiary:active:before {
  -webkit-animation-name: hvr-ripple-in;
  animation-name: hvr-ripple-in;
}

.btn-icon {
  display: flex;
  align-items: center;
  .menu-icon {
    width: 20px;
    height: 20px;
  }
}

.high-res {
  margin-top:10%;
}

.mr-5 {
  margin-right: 1%;
}