@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.SimpleBanner {
  background: colors.$secondary-color;
  color: #fff;
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  @include media.respond-to(maxmobile) {
    flex-direction: column-reverse;
  }

  &__text {
    text-align: left;
    @include media.respond-to(maxmobile) {
      text-align: center;
    }
  }

  &__image {
    img {
      width: 400px;
      @include media.respond-to(maxmobile) {
        width: 200px;
      }
    }
  }

  &__stats {
    font-size:1.4rem;
    margin-bottom: 2rem;
    line-height: 2rem;
    @include media.respond-to(maxmobile) {
      font-size:unset;
      line-height: 1.5rem;
    }
  }

  &__title {
    margin: 0.5rem 0;
  }

  &__subtitle {
    font-size: 1.2rem;
    margin: 0;
  }

  

}
