@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.ProductImageCarousel {
  display: flex;
  gap: 1rem;

  &__thumbnails {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 400px;
    width: 80px;
    @include media.respond-to(maxmobile) {
      height: 250px;
    }
  }

  &__thumbnail-slider {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__thumbnail {
    width: 100%;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border-color 0.3s;

    &:hover,
    &:focus {
      border-color: colors.$primary-color;
    }
  }

  &__main-image-container {
    position: relative;
    width: 500px;
    height: 500px;
    overflow: hidden;
    -webkit-box-shadow: -11px 10px 29px -3px rgba(181,181,181,0.53);
    -moz-box-shadow: -11px 10px 29px -3px rgba(181,181,181,0.53);
    box-shadow: -11px 10px 29px -3px rgba(181,181,181,0.53);
    @include media.respond-to(maxmobile) {
     width: 100%;
     height: 100%;
    }
  }

  &__main-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: zoom-in;
    @include media.respond-to(maxmobile) {
      height: auto;
    }
    &.zoomed {
      transform: scale(1.5);
      cursor: zoom-out;
    }
  }

  &__zoom-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color:transparent;
    border: none;
    padding: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
    border-radius: 50%;
    transition: color 0.3s, transform 0.2s;

    &:hover {
      color: colors.$primary-color;
      transform: scale(1.1);
    }
  }
}

.ProductImageCarousel__overlay {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ProductImageCarousel__modal {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.ProductImageCarousel__zoomed-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.ProductImageCarousel__close-button {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 20px;
  right: 20px;
  background: #ffffff;
  border: none;
  color: #333;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
    color: colors.$primary-color;
  }
}
