@use '../mixins/media-queries' as media;

.ProductCarousel {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media.respond-to(maxmobile) {
      flex-direction: column;
    }
    &--text {
      @include media.respond-to(maxmobile) {
        text-align: center!important;
      }
    }
  }
  &__title {
    text-align: left!important;
    @include media.respond-to(maxmobile) {
      text-align: center!important;
    }
  }
  &__subtitle {
    text-align: left!important;
    @include media.respond-to(maxmobile) {
      text-align: center!important;
      margin-bottom:1rem;
    }
  }
  .slick-list {
    margin:4rem 0 0 0;
    @include media.respond-to(maxmobile) {
      margin:1rem 0 0 0;
    }
  }
  .slick-next:before, 
  .slick-prev:before {
    opacity: 0;
  }
  .slick-prev {
    left: -50px!important;
    @include media.respond-to(maxmobile) {
      left:30px!important;
    }
  }
  .slick-next {
    right: -50px!important;
    @include media.respond-to(maxmobile) {
      right:30px!important;
    }
  }
}
