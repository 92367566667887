@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.hero-slider {
  position: relative;
  overflow: hidden;
  padding:0;

  .slick-slider {
    -webkit-box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);
    -moz-box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);
    box-shadow: -1px 2px 12px 3px rgba(181,181,181,0.53);
  }

  &__slide {
    position: relative;
    img {
      width: 100%;
      height: auto;
      display: block;
      border-radius: 10px;
    }

    .slide-content {
      background: rgb(0 0 0 / 49%);
      color: white;
      position: absolute;
      width: 100%;
      padding: 5%;
      top: 50%;
      text-align: center;
      transform: translateY(-50%);
      z-index: 5;

      @include media.respond-to(maxmobile) {
        width: 80%;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
      } 

      @include media.respond-to(desktop) {
        width: 40%;
        top: 50%;
        transform: translateY(-50%);
        right: 5%;
      }  

      h1.h1 {
        text-transform: uppercase;
        font-weight: bold;
        @include media.respond-to(desktop) {
          font-size:3rem;
        }  
      }

      h2 {
        font-weight: bold;
        @include media.respond-to(desktop) {
          font-size:3rem;
        }  
      }

      p {
        font-size: 1.25rem;
      }

      .btn-primary {
        margin-top:20px;
      }

    }
  }
  
  .slick-next:before, 
  .slick-prev:before {
    opacity: 0;
  }

  .slick-dots {
    bottom: 20px;
    li button:before {
      font-size: 12px;
      color: white;
    }
    li.slick-active button:before {
      color: #ffc107;
    }
  }
}