@use '../variables/colors' as colors;

.QuantitySelector {
  display: flex;
  flex-direction: column;
  width: 100%;

  h3 {
    margin:2rem 0 0.5rem 0;
  }

  &__slider-container {
    position: relative;
    width: 100%;
  }

  &__slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background: #d3d3d3;
    outline: none;
    transition: background 0.3s;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      background: colors.$primary-color;
      cursor: pointer;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      width: 20px;
      height: 20px;
      background: colors.$primary-color;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  &__tooltip {
    position: absolute;
    top: -30px;
    transform: translateX(-50%);
    padding: 4px 8px;
    background-color: colors.$primary-color;
    color: #fff;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.5rem;
    color: #3f3d56;
    font-size: 0.9rem;
  }
}
