@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.ProductPage {
  .row {
    margin:4rem 0;
  }
  .QuantitySelector {
    &__controls {
      display: flex;
    }
    &__button {
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 4px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      transition: background-color 0.3s;
    }
    &__input {
      font-size:1rem;
      border: none;
      text-align: center;
      -webkit-appearance: none;
      margin: 0;
    }
  }
  &__title {
    @include media.respond-to(maxmobile) {
      margin-top: 1.5rem;
    }
  }
  &__reference {
    display: block;
    margin-bottom: 0.5rem;
  }
  &__description {
    margin-bottom: 0.5rem;
    line-height: 1.2rem;
    div {
      line-height: 1.3rem; 
    }
  }
  &__packinginfo {
    margin-bottom: 0.5rem;
    .ProductPackagingInfo {
      div {
        margin:0.5rem 0;
      }
    }
    .ProductPackagingInfo__row {
      margin-bottom:1rem;
    }
    .ProductPackagingInfo__label {
      margin-bottom:1rem;
    }
    .ProductPackagingInfo__value {
      margin-left: 10px;
      strong {
        text-transform: capitalize;
      }
    }
  }
  &__variations-list {
    ul {
      list-style: none; 
      margin: 0;
      padding: 0;
      li {
        background-color: #ffffff;      
        border-left: 4px solid colors.$primary-color;   
        padding: 12px 16px;               
        margin-bottom: 8px;               
        border-radius: 4px;                
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  
        transition: background-color 0.3s ease, transform 0.3s ease;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          background-color: #fff1f1; 
          transform: translateX(4px); 
        }
      }
    }

  }
  &__variation-selector {
    h3 {
      font-size: 1.3rem;
      margin:2rem 0 0.5rem 0;
    }
  }
  &__quantity-selector {
    margin-bottom: 2rem;
  }
  &__VariationsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    img {
      max-width: 10%;
    }
    h2 {
      margin-left: 20px;
    }
  }
  &__FAQ {
    padding:0;
  }
}

.info__title {
  font-size: 1.1rem;
  font-weight: bold;
  color: colors.$primary-color;
}

.title__spacing {
  display: block;
  margin-bottom: 0.8rem;
}