@use '../variables/colors' as colors;

.header-responsive {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    .header__hamburger {
      background: none;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      background-color:colors.$secondary-color;
    
      &:hover {
        background-color: #f3f3f3;
        border-radius: 4px;
      }
      &-icon {
        font-size: 24px; 
        color:colors.$background-color;
      }
    }
    .header__logo--responsive {
      img {
        width: 150px;
        height: auto;
      }
    }
  }

  .header__sidebar {
    position: fixed;
    top: 0;
    right: -100%;
    width: 80%;
    height: 100%;
    background-color: white;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    transition: right 0.3s ease-in-out;
    z-index: 1000;

    &.open {
      right: 0;
    }

    .header__sidebar-close {
      background: none;
      border: none;
      font-size: 24px;
      padding: 10px;
      cursor: pointer;
    }

    .header__mobile-menu-list {
      list-style: none;
      padding: 0;
      margin: 0;

      .header__mobile-menu-item {
        padding: 15px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;

        .header__mobile-submenu {
          list-style: none;
          padding-left: 20px;
          margin: 10px 0;

          .header__mobile-submenu-item {
            padding: 10px 0;

            .header__mobile-subsubmenu {
              list-style: none;
              padding-left: 20px;
              margin-top: 5px;

              li {
                padding: 5px 0;
              }

              a {
                text-decoration: none;
                color: #333;
                transition: color 0.2s;

                &:hover {
                  color: #007bff;
                }
              }
            }
          }
        }
      }
    }
  }

  .header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
  }
}
