@use '../variables/breakpoints' as breakpoints;

@mixin respond-to($breakpoint) {
  @if $breakpoint == mobile {
    @media (min-width:breakpoints.$breakpoint-mobile) { @content; }
  }
  @else if $breakpoint == tablet {
    @media (min-width:breakpoints.$breakpoint-tablet) { @content; }
  }
  @else if $breakpoint == desktop {
    @media (min-width:breakpoints.$breakpoint-desktop) { @content; }
  }
  @else if $breakpoint == maxmobile {
    @media (max-width:breakpoints.$breakpoint-desktop) { @content; }
  }
  @else if($breakpoint == $large-desktop) {
    @media (min-width: breakpoints.$breakpoint-lg-desktop) { @content; }
  }
}