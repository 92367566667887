@use '../variables/colors' as colors;

.ProductCard {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  margin:0 10px;
  background-color: #fff;
  padding: 1rem;
  text-align: center;

  &__image {
    margin-bottom: 0.5rem;
  }

  &__image-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  &__img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  &__logo {
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: 40px;
    height: auto;
    z-index: 10;
  }

  &__title {
    font-size: 1.2rem;
    margin: 0.5rem 0;
  }

  &__footer {
    margin-top: auto;
    text-align: center;
  }

  &__warehouse {
    margin-top: 1rem;
    text-align: center;
    &--title {
      margin-right: 0.3rem;
      font-weight: bold;
    }
    &--value a {
      color: colors.$primary-color; 
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
