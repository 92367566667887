@use '../variables/colors' as colors;
@use '../variables/fonts' as fonts;
@use '../mixins/media-queries' as media;

body {
  overflow-x: hidden;
  font-family: fonts.$body-regular;
  font-size: 14px;
  @include media.respond-to(tablet){
    font-size:16px;
  }
}

.General__container {
  padding:5% 0;
  h2, p {
    text-align: center;
  }
}

a {
  cursor:pointer;
  color:colors.$secondary-color;
  text-decoration: none;
  &:hover {
    color:colors.$primary-color
  }
}

.slick-arrow {
  width: 30px!important;
  height: 30px!important;
  @include media.respond-to(desktop) {
    width: 40px!important;
    height: 40px!important;
  } 
}


.primary-color {
  color:colors.$primary-color;
}

.bolded {
  font-family: fonts.$body-medium!important;
}