
.CategoryProductsPage {

  &__results {
    margin:4rem 0;
    .infinite-scroll-component {
      overflow: hidden!important;
      .products-row {
        margin-bottom: 4rem;
      }
    }
    .Pagination__status {
      text-align: center;
    }
  }

}