@use '../variables/colors' as colors;

.VariationSelector {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  input[type='radio'] {
    display: none;
  }

  &__option {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: 2px solid transparent;
    background-color: #f0f0f0;
    color: #333;
    font-size: 0.7rem;
    text-transform: capitalize;
    transition: border-color 0.3s;

    &.color {
      background-color: #ddd; // Color de fondo predeterminado si no se encuentra en el mapa de colores
    }

    &.text {
      width: 100px;
      border-radius: 5px;
      padding: 0 0.5rem;
      border: 1px solid #ddd;
      background-color: #f0f0f0;
    }

    &:hover,
    &:focus {
      border-color: colors.$secondary-color;
    }
  }

  // Aplica el borde azul cuando está seleccionado
  input[type='radio']:checked + .VariationSelector__option {
    border-color: colors.$secondary-color;
  }
}
