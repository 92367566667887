@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.Topbar {
  background-color: colors.$secondary-color; // Reemplaza por tu variable de color
  color: #fff;
  text-align: center;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  p {
    margin: 0;
    font-size:0.7rem;
    @include media.respond-to(desktop) {
      font-size: 1rem;
    }
  }
}
