@use '../variables/colors' as colors;

.megamenu {
  display:flex;
  justify-content: center;
  align-items: center;
  padding:10px 0;
  .megamenu__list {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .megamenu__item {
    font-size:1.1rem;
    font-weight: bold;
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    height: 40px;
  }

  .megamenu__item:hover .megamenu__link,
  .megamenu__item.active .megamenu__link {
    border-bottom: 3px solid colors.$primary-color;
  }

  .megamenu__link {
    display: flex;
    align-items: center;
  }

  .megamenu__arrow {
    margin-left: 5px;
    transition: transform 0.3s;
    width: 10px; // Ajusta el tamaño de la flecha según sea necesario
    height: 10px;
  }

  .megamenu__arrow.up {
    transform: rotate(180deg);
  }

  .megamenu__arrow.down {
    transform: rotate(0deg);
  }

  .megamenu__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    z-index: 1000;
    width: auto;
  }

  .megamenu__item:hover .megamenu__dropdown {
    display: grid;
  }

  .megamenu__item:hover .megamenu__columns {
    display: flex;
    padding: 2rem;
    width: 800px;
  }

  .megamenu__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .megamenu__group-title {
    margin-bottom: 10px;
    font-size: 1.1rem;
  }

  .megamenu__dropdown-link {
    display: block;
    margin-bottom: 5px;
    color: black;
    text-decoration: none;
    font-size: 1.1rem;
    transition: color 0.2s;
    margin-bottom: 10px;
  }

  .megamenu__dropdown-link:hover {
    color: colors.$primary-color;
  }
}
