@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.Footer {
  background:colors.$secondary-color;
  color:white;
  padding:2rem 5rem;
  @include media.respond-to(maxmobile) {
    text-align: center;
  }
  &__logo {
    margin:3rem auto;
    img {
      width: 180px;
    }
  }
  a {
    color:white;
  }
  &__info.center {
    text-align: center;
    .Footer__info--head {
      justify-content: center;
    }
  }
  &__info {
    &--head {
      display: flex;
      align-items: center;
      margin:10px 0;
    }
    &--text {
    }
    &--title {
      font-size: 1.5rem;
      @include media.respond-to(maxmobile) {
        margin:1rem 0;
      }
    }
    &--contact {
      a {
        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left: 10px;
        }
      }
    }
    &--payment {
      img {
        width: 100%;
      }
    }
  }
  &__copyright {
    margin-top: 3rem;
    text-align: center;
  }
}