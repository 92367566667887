@use '../variables/colors' as colors;

.QuotationForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top:2rem;

  label {
    display: block;
    margin-bottom: 10px;
    color: colors.$secondary-color;
  }

  &__success {
    padding: 65% 2%;
    text-align: center;
    h3 {
      font-size:1.8rem;
    }
    p {
      margin-bottom:1rem;
    }
  }

  input,
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    margin-top: 5px;
    transition: border-color 0.3s;

    &:focus {
      border-color: colors.$primary-color;
      outline: none;
      box-shadow: 0 0 5px colors.$primary-background;
    }
  }

  .error {
    color: colors.$primary-color;
    font-size: 0.875rem;
    margin-top: 5px;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }

  .QuotationForm__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
}
