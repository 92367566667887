@use '../variables/colors' as colors;

.LoginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: colors.$background-color;

  h2 {
    color: colors.$primary-color;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 400px;
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  label {
    display: flex;
    flex-direction: column;
    color: #333;
    font-weight: 600;

    input {
      margin-top: 0.5rem;
      padding: 0.8rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      outline: none;
      transition: border-color 0.3s ease;

      &:focus {
        border-color: colors.$primary-color; // Color principal en foco
      }
    }
  }

  button {
    padding: 0.8rem;
    background-color: colors.$primary-color; // Botón con color principal
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: colors.$secondary-color;
      color:white;
    }

    &:focus {
      outline: none;
    }
  }
}
