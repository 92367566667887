@use '../variables/colors' as colors;

.CategoryTags {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.CategoryTag {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #f5f5f5;
  text-decoration: none;
  color: #333;
}

.CategoryTag.active {
  background-color: colors.$secondary-color;
  color:white
}

.CategoryTag img {
  margin-right: 8px;
  width: 20px;
  height: 20px;
}

.CategoryTags__toggle {
  background: none;
  border: none;
  color:colors.$primary-color;
  cursor: pointer;
  margin-top: 10px;
}
