@use '../variables/colors' as colors;

.ProductVariationsTable {
  margin-top: 2rem;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #e0e0e0; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 

  .empty_inventory {
    padding: 2% 10%;
    text-align: center;
  }

  &__title {
    background-color: colors.$primary-background;
    color: colors.$primary-color;
    text-align: center;
    padding: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: bold;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;

    thead {
      background-color: colors.$background-color!important;
    }

    th, td {
      border: 1px solid #e0e0e0; 
      padding: 0.75rem 1rem;
      font-size: 1rem;
    }

    th {
      color: colors.$secondary-color; 
      text-transform: uppercase;
    }

    td {
      color: colors.$secondary-color; 
    }

    tr {
      background-color: white; 
    }
  }
}
