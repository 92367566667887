@use '../variables/colors' as colors;
@use '../mixins/media-queries' as media;

.MasonryGrid {
  position: relative;
  background-color: colors.$background-color;
  padding-bottom: 2rem;

  &__title {
    text-align: center;
  }

  &__subtitle {
    padding: 0 20%;
    text-align: center;
  }

  &__cta {
    text-align: center;
    margin: 2rem 0 4rem 0;
  }

  &__wave-top {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    z-index: -1;
    height: auto;
    display: block;
    @include media.respond-to(maxmobile) {
      top: -5%;
    }
  }

  .masonry {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 10px;
    margin: 0 auto;
    padding: 40px 0;

    &__item {
      position: relative;
      overflow: hidden;
      border-radius: 12px;
      text-align: center;

      /* Por defecto, no forzamos la primera tarjeta a ser más grande en pantallas pequeñas. */
      &--0 {
        // Eliminamos grid-column/row aquí o lo dejamos vacío
      }
    }

    &__link {
      display: block;
      text-decoration: none;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.63);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &__title,
    &__subtitle {
      color: #ffffff;
      margin: 0;
    }

    &__title {
      font-size: 1.3rem;
      font-weight: bold;
    }

    &__subtitle {
      font-size: 1rem;
    }

    &__item:hover .masonry__image {
      transform: scale(1.1);
    }

    &__item:hover .masonry__overlay {
      opacity: 1;
    }
  }

  /* -----------------------------
     MEDIA QUERY PARA PANTALLAS GRANDES
     (ej. a partir de 1200px de ancho)
     Ajusta el breakpoint según necesites
  ------------------------------ */
  @media (min-width: 1200px) {
    .masonry {
      /* Fijamos 5 columnas */
      grid-template-columns: repeat(5, 1fr);
      /* 
         Para simular un “masonry” más controlado, 
         podemos usar un alto de fila fijo. 
         Por ejemplo, 220px. Ajusta a tu gusto.
      */
      grid-auto-rows: 220px;
      grid-auto-flow: dense; 
    }

    /* 
       Aquí hacemos que la primera tarjeta 
       ocupe 2 columnas y 2 filas.
    */
    .masonry__item--0 {
      grid-column: span 2; 
      grid-row: span 2;
    }
  }
}
