@use '../mixins/media-queries' as media;
@use '../variables/colors' as colors;

.header {
  background: #ffffff;
  transition: all 0.3s ease;
  
  &__user-info {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    flex-direction: column;
    button {
      background-color: transparent;
      color:colors.$primary-color;
      padding:0;
    }
  }

  &--sticky {
    position: fixed;
    top: 0;
    width: 100%; 
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Opcional: agrega una sombra para destacarlo
  }

  &__desktop {
    display: none;
  }

  &__logo {
    max-width: 280px;
  }

  &__top-row {
    align-items: center;
    padding: 1rem;
  }

  &__contact {
    margin-left:2rem;
    &-content {
      a {

        &:first-child {
          margin-right: 10px;
        }
        &:last-child {
          margin-left:10px;
        }
      }
    }
  }

  &__contact-head {
    display: flex;
    align-items: center;
    margin-bottom:10px;
    span {
      margin-left: 10px;
    }
  }

  // Menu (desktop)
  &__menu-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // Menu mobile (hamburguesa)
  &__mobile {
    padding: 10px 0;
    background-color: #e9ecef;

    .header__hamburger {
      background: none;
      border: none;
      font-size: 30px;
      cursor: pointer;
    }

    .header__icons {
      text-align: cen;
      span {
        margin-left: 15px;
      }
    }
  }

  // Sidebar styles responsive menu
  &__sidebar {
    position: fixed;
    top: 0;
    right: -100%; // Inicialmente fuera de la pantalla
    width: 300px;
    height: 100%;
    background-color: #343a40;
    transition: right 0.3s ease;
    z-index: 1001;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);

    &.open {
      right: 0; // Cuando está abierto, entra a la pantalla
    }

    // Close sidebar styles
    &-close {
      background: none;
      border: none;
      color: white;
      font-size: 30px;
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }

    // Menu internal sidebar styles
    .header__mobile-menu {
      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          margin: 15px 0;
          text-align: center;

          a {
            color: white;
            text-decoration: none;
            font-weight: bold;

            &:hover {
              color: #ffc107;
            }
          }
        }
      }
    }
  }

  // Dark background when sidebar is open
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }

  // Show hamburguer menu in responsive
  @include media.respond-to(desktop) {
    .header__desktop {
      display: block;
    }

    .header-responsive {
      display:none;
    }

  }  

}

