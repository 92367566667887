@use '../variables/colors' as colors;

.benefits-row {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 6rem;

  .benefit {
    display: flex;
    align-items: center;
    margin: 0 1rem;

    &__icon {
      font-size: 2rem; 
      color: colors.$primary-color;  
      margin-right: 0.5rem;
    }

    &__text {
      font-weight: 500;
      font-size: 1rem; 
      color: #333;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    .benefit {
      margin: 1rem 0;
      justify-content: center; // Centra horizontalmente
    }
  }
}
