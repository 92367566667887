@use '../variables/colors' as colors;

.MarqueeText {
  overflow: hidden;
  background-color: colors.$secondary-color;
  position: relative;
  width: 100%;

  &__content {
    display: inline-flex;
    padding:2rem;
    white-space: nowrap;
    animation: marquee 15s linear infinite; // 15s = duración del scroll, ajústalo

    &--text {
      font-size: 1.5rem;
      color: colors.$background-color; 
      margin: 0 2rem;
      display: inline-block;
    }

    &--icon {
      width: 30px;
      height: auto;
      margin-right: 2rem;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
