@use '../variables/colors' as colors;

.FAQ {
  margin-top: 20px;
  padding: 30px 0;
  border-radius: 12px;

  &__title {
    color: colors.$primary-color;
    margin-bottom: 20px;
    font-weight: 700;
    text-align: center;
  }

  &__item {
    margin-bottom: 20px;

    &:focus {
      outline: none; 
      box-shadow: 0 0 0 3px rgba(240, 42, 42, 0.5); 
      border-radius: 8px; 
    }

    .FAQ__question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.4rem;
      color: colors.$primary-color;
      padding: 2rem 0;

      &:focus {
        outline: none; 
        box-shadow: 0 0 0 3px rgba(240, 42, 42, 0.5); 
        border-radius: 8px; 
      }

      &.active {
      }
    }

    .FAQ__answer {
      padding: 15px 0;
      font-size: 1.2rem;
      line-height: 1.5;
    }

    &__divider {
      border: none;
      border-top: 1px solid #d1cfe2; // Borde de separación
    }
  }

  &__icon {
    width: 23px;
    height: 23px;
    transition: transform 0.3s ease;
  }

  .rotated {
    transform: rotate(180deg);
  }
}
